import React, {useState, useEffect} from 'react';
import axios from 'axios'
import styled from 'styled-components';

const PopupWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    ${props => {
        if(props.open) {
            return `
                display: block;
            `
        } else {
            return `
                display: none;
            `
        }
    }}
`;

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    ${props => {
        if(props.open) {
            return `
                display: block;
            `
        } else {
            return `
                display: none;
            `
        }
    }}
`;

export const PopupInner = styled.div`
    max-width: 1024px;
    width: 80%;
    height: 90%;
    position: relative;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #fff;
    border-radius: 6px;
    overflow-y: scroll;
    line-height: 1.2;
`;

export const Content = styled.div`
    color: #000;
    p{
        color: #000;
        line-height: 25px;
        margin-bottom: 30px;
    }
    td,tr{
        margin: 10px 0;
        border: 1px solid #111;
        padding: 5px 0;
        font-size: 13px;
    }
    li{
        font-size: 14px;
    }
    h3{
        font-weight: 500;
        line-height: 40px;
        color: #111;
    }
    h3, p {
        font-size: 15px;
    }
    padding: 30px;

    @media(max-width: 720px){
        h3, p {
            font-size: 14px;
        }
        padding: 15px;
    }
`;

export const PopupClose = styled.div`
    a{
        text-decoration: none;
        z-index: 9000;
    }
    .icon-close{
        position: absolute;
        top: 17px;
        right: 17px;
        color: #111;
        
    }
`;

const closeIcon = (<svg xmlns="close.svg" width="21" height="21" viewBox="0 0 18 18"><path d="M13 5c.364 0 .659.295.659.659l-.001 7.682h7.683c.364 0 .659.295.659.659 0 .364-.295.659-.659.659l-7.683-.001v7.683c0 .364-.294.659-.658.659-.364 0-.659-.295-.659-.659v-7.683H4.659C4.295 14.659 4 14.365 4 14c0-.364.295-.659.659-.659h7.682V5.659c0-.364.295-.659.659-.659z" transform="translate(-29 -18) translate(23 11) rotate(45 13 14)"/></svg>)


const Terms = (props) => {

    const [page_item, set_page_item] = useState("");
    useEffect(() => {
        if(!page_item){
            axios.get('https://wp.yepum.co.kr/wp-json/ep/v1/page/2912',{})
            .then(response => {
                if(response.data.code == 200){
                    set_page_item(response.data.body);
                }
            })
            .catch(error => console.log(error));
        } 
        console.log(page_item);
    });
    

    return (
        <PopupWrapper open={props.open}>
            <Overlay open={props.open} onClick={props.onClose}/>
            <PopupInner open={props.open}>
                <PopupClose> 
                    <a href="/#" onClick={props.onClose} className={"icon-close"}>{closeIcon}</a>
                </PopupClose>
                <Content dangerouslySetInnerHTML={{
                        __html: page_item.post_content
                    }}>
                </Content>
            </PopupInner>
        </PopupWrapper>
    );
};

export default Terms;