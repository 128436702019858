import './base.css';
import './pc.css';
import './normalize.css';
// IE11의 경우
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React,{useState} from 'react'
import Slider from "react-slick";
import Privacy from "./views/Privacy"
import Terms from "./views/Terms"



function App() {

  const settings ={
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    slidesToShow: 1,
}

const[open_privacy, set_open_privacy] = useState(0)
const privacy_toggle = (e) => {
  if (open_privacy) {
    set_open_privacy(0);
  } else {
    set_open_privacy(1);
  };
};

const[open_terms, set_open_terms] = useState(0)
const terms_toggle = (e) => {
  if (open_terms) {
    set_open_terms(0);
  } else {
    set_open_terms(1);
  };
};

  return (
    <>
        <div class="blur">
          <div class="box main">
            <div class="flexible ver-fit flex-center">
              <div class="flex-1 main-details">
                <h2>예도의 품격을<br class="only-pc" /> 갖춘 APP</h2>
                <h1>예품</h1>
                <p>별이 되신 고인을 정중하게 보내드리며<br class="only-pc" /> 가족분들에게 위로의 마음을 전달합니다.</p>
                <div class="flexible buttons">
                  <a href="https://play.google.com/store/apps/details?id=kr.co.yepum.app" target="_blank" class="button googleplay">
                    <img src="res/google.png" />Google Play
                  </a>
                  <a href="https://apps.apple.com/kr/app/%EC%98%88%ED%92%88/id1549635558" target="_blank" class="button appstore">
                    <img src="res/apple.png" />App Store
                  </a>
                </div>
              </div>
              <div class="flex-1 only-pc">
                <div class="flexible">
                  <div class="flex-1 splash">
                    <img src="res/s2.png" />
                  </div>
                  <div class="flex-1 splash">
                    <img src="res/m3-1.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box intro">
          <h3>Create a high quality</h3>
          <h2>모든 예식 (禮式) 의 <br class="only-mobile" />‘높은 품격’을 만들다</h2>
          <p>소중한 별이 되신 고인분에 대하여 예를 다하여 정중히 모시고<br class="only-pc" /> 가족분들에게 많은 분들의 위로의 마음을 전달해드리는 서비스입니다.</p>
          <p>이를 통해 장례문화의 품격을 높이고자 합니다.</p>
          <img src="res/bitmap.png" />
        </div>
        <div class="box service">
          <h2>Main Service</h2>

          <div class="image-wrapper">
            <img class="only-pc" src="res/invalid-name.png" />
            <img class="only-mobile" src="res/service.png" />
          </div>
        </div>
        <div class="box section section-1">
          <div class="flexible">
            <div class="flexible flex-4 only-pc img-left">
              <div class="section-img m1-1">
                <div class="img" src="res/1-1.png"></div>
                <img src="res/1-1.png" />
              </div>
              <div class="section-img m1-2">
                <div class="img" src="res/1-2.png"></div>
                <div class="img" src="res/1-3.png"></div>
                <img src="res/1-2.png" />
                <img src="res/1-3.png" />
              </div>
              <div class="section-img">
                <div class="img" src="res/1-4.png"></div>
                <div class="img" src="res/1-5.png"></div>
                <img src="res/1-4.png" />
                <img src="res/1-5.png" />
              </div>
            </div>
            <div class="flex-3">
              <div class="feature">
                <h3>01</h3>
                <div class="hr"></div>
              </div>
              <div class="details">
                <h2>예를 다하여 마음을<br /> 전합니다</h2>
                <ul>
                  <li>스마트폰으로 편하게 부고장을 전달하세요.</li>
                  <li>예품 부고장을 통해 장례식장에 편하게 찾아갈 수 있습니다.</li>
                  <li>추모갤러리를 통해 고인의 행복했던 모습을 기억해주세요.</li>
                  <li>별이되신 고인분께 추모메세지를 남겨주세요.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="only-mobile slickwrapper">
            <Slider {...settings}>
              <div className="card"><div className="cardinner"><img src="res/m1-1.png"/></div></div>
              <div className="card"><div className="cardinner"><img src="res/m1-2.png"/></div></div>
              <div className="card"><div className="cardinner"><img src="res/m1-3.png"/></div></div>
              <div className="card"><div className="cardinner"><img src="res/m1-4.png"/></div></div>
              <div className="card"><div className="cardinner"><img src="res/m1-5.png"/></div></div>
            </Slider>
          </div>
        </div>
        <div class="box section section-2">
          <div class="flexible">
            <div class="flex-3">
              <div class="feature">
                <div class="hr"></div>
                <h3>02</h3>
              </div>
              <div class="details right">
                <h2>누구나 볼 수 있는<br /> 부고장을<br /> 만들어보세요</h2>
                <ul>
                  <li>스마트폰으로 손쉽게 품격있는 부고장을 작성하세요.</li>
                  <li>소중한 분과의 추억을 추모갤러리를 통해<br class="only-pc" /> 부고장에서 나눌 수 있습니다.</li>
                </ul>
              </div>
            </div>
            <div class="flexible flex-4 only-pc img-right">
              <div class="section-img">
                <div class="img" src="res/2-1.png"></div>
                <img src="res/2-1.png" />
              </div>
              <div class="section-img">
                <div class="img" src="res/2-2.png"></div>
                <div class="img" src="res/2-3.png"></div>
                <img src="res/2-2.png" />
                <img src="res/2-3.png" />
              </div>
              <div class="section-img">
                <div class="img" src="res/2-4.png"></div>
                <img src="res/2-4.png" />
              </div>
            </div>
          </div>
          <div class="only-mobile slickwrapper">
            <Slider {...settings}>
              <div className="card"><div className="cardinner"><img src="res/m2-1.png"/></div></div>
              <div className="card"><div className="cardinner"><img src="res/m2-2.png"/></div></div>
              <div className="card"><div className="cardinner"><img src="res/m2-3.png"/></div></div>
              <div className="card"><div className="cardinner"><img src="res/m2-4.png"/></div></div>
            </Slider>
          </div>
        </div>
        <div class="box section section-3">
          <div class="flexible">
            <div class="flexible flex-4 only-pc img-left">
              <div class="section-img">
                <div class="img" src="res/3-1.png"></div>
                <img src="res/3-1.png" />
              </div>
              <div class="section-img">
                <div class="img" src="res/3-2.png"></div>
                <img src="res/3-2.png" />
              </div>
              <div class="section-img">
                <div class="img" src="res/3-3.png"></div>
                <img src="res/3-3.png" />
              </div>
            </div>
            <div class="flex-3">
              <div class="feature">
                <h3>03</h3>
                <div class="hr"></div>
              </div>
              <div class="details">
                <h2>품격있는 화환으로<br /> 위로의 마음을<br /> 전합니다.</h2>
                <ul>
                  <li>부고장에서 바로 화환을 빠르게 보내실 수 있습니다.</li>
                  <li>별도의 장례식장 정보 없이 화환 배송이 가능합니다.</li>
                  <li>품격있는 화환으로 예를 다하여 마음을 전해드립니다.</li>
                  <li>예품에서는 위로의 마음을 전달한 분에게도 선물을 드립니다.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="only-mobile slickwrapper">
            <Slider {...settings}>
              <div className="card"><div className="cardinner"><img src="res/m3-1.png"/></div></div>
              <div className="card"><div className="cardinner"><img src="res/m3-2.png"/></div></div>
              <div className="card"><div className="cardinner"><img src="res/m3-3.png"/></div></div>
            </Slider>
          </div>
        </div>
        <div class="box section section-4 lastsection">
          <div class="flexible">
            <div class="flex-3">
              <div class="feature">
                <div class="hr"></div>
                <h3>04</h3>
              </div>
              <div class="details right">
                <h2>언택트 시대에도<br /> 마음을 전할 수<br /> 있습니다.</h2>
                <ul>
                  <li>부의금 계좌 서비스를 통해 상주님에게<br class="only-pc" /> 부의금을 전달할 수 있습니다.</li>
                </ul>
              </div>
            </div>
            <div class="flexible flex-4 only-pc img-right">
              <div class="section-img">
                <div class="img" src="res/4-1.png"></div>
                <img src="res/4-1.png" />
              </div>
              <div class="section-img m4-2">
                <div class="img" src="res/4-2.png"></div>
                <img src="res/4-2.png" />
              </div> 
              <div class="section-img m4-3">
                <div class="img" src="res/4-3.png"></div>
                <img src="res/4-3.png" />
              </div>
            </div>
          </div>
          <div class="only-mobile slickwrapper">
            <Slider {...settings}>
              <div className="card"><div className="cardinner"><img src="res/m4-1.png"/></div></div>
              <div className="card"><div className="cardinner"><img src="res/m4-2.png"/></div></div>
              <div className="card"><div className="cardinner"><img src="res/m4-3.png"/></div></div>
            </Slider>
          </div>
        </div>
        <div class="footer">
          <div class="copy">
            <p>Copyright&copy; YEPUM 2020 All Right Reserved</p>
            <ul>
              <li onClick={terms_toggle} style={{cursor: 'pointer'}}>서비스이용약관</li>
              <li onClick={privacy_toggle} style={{fontWeight: 500, cursor: 'pointer'}}>개인정보처리방침</li>
            </ul>
          </div>
          <p>서울시 영등포구 여의대방로 43나길 6 인우빌딩<br class="only-mobile" /><span class="only-pc"> ｜</span> 대표번호 : 1544-3554<br
              class="only-pc" /><span class="only-mobile"> ｜</span> 대표이사 : 한선화<br class="only-mobile" /><span class="only-pc">
              ｜</span> 사업자등록번호 403-94-14250<br class="only-mobile" /><span class="only-pc"> ｜</span> 통신판매업 제 2020-서울영등포-3597호
          </p>
          <Privacy open={open_privacy} onClose={privacy_toggle}/>
          <Terms open={open_terms} onClose={terms_toggle}/>
        </div>
    </>
  );
}

export default App;
